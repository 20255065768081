import { ApprovalsPath, ModuleType } from "@enums";

export const ROUTING = {
    HOME: `/`,
    
    APPROVALS:`/${ModuleType.ManageApprovals}`,
    APPROVALS_DETAILS:`/${ModuleType.ManageApprovals}/${ApprovalsPath.PROFILE_DETAILS}`,
    APPROVALS_DETAILS_DOCUMENTS:`/${ModuleType.ManageApprovals}/${ApprovalsPath.DOCUMENT_DETAILS}`,
    APPROVALS_DETAILS_BENEFICIARIES:`/${ModuleType.ManageApprovals}/${ApprovalsPath.BENEFICIARIES_DETAILS}`,

    CASH_ACCOUNT_MANAGEMENT:`/${ModuleType.ManageCashAccountManagement}`

  };

  export const BACKEND_ENDPOINTS = {
    APPROVALS:{
      PROFILE_LISTING : () => `profile-approval`,
      PROFILE_DETAILS : (id:string) => `profile-approval/${id}`,
      APPROVALS_COUNT : () => `approvals-count`,
      PROFILE_APPROVAL : (id:string) => `profile-approval/${id}`,
      DOCUMENT_LISTING : () => `document-approval`,
      DOCUMENT_DETAILS : (id:string) => `document-approval/${id}`,
      DOCUMENT_PDF_CONTENT : () => `document-approval/load-attachment`,
      BENEFICIARIES_LISTING : () => `beneficiary-approvals`,
      BENEFICIARIES_DETAILS : (id:string) => `beneficiary-approvals/${id}`,
      BENEFICIARIES_APPROVAL : (id:string) => `beneficiary-approvals/${id}`,
    }
  
  };
  