
export const DOCUMENTS_STATUS = Object.freeze({
  PENDING_APPROVAL: 'Pending approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  EXPIRED: 'Expired',
  DRAFT: 'Draft',
  IN_PROGRESS: 'In progress',
});

export const APPROVALS_STATE = Object.freeze({
  PROFILE: 'Profile',
  DOCUMENTS: 'Documents',
  EXPENSES: 'Expenses',
  CASH_ADVANCES: 'Cash advances',
  BENEFICIARIES: 'Beneficiaries',
 
});

export const APPROVALS_TABS = Object.freeze([
    { key: 'PROFILE', value: 'Profile'},
    { key: 'DOCUMENTS', value: 'Documents' },
    { key: 'EXPENSES', value: 'Expenses' },
    { key: 'CASH_ADVANCES', value: 'Cash advances' },
    { key: 'BENEFICIARIES', value: 'Beneficiaries' }
]);


export const APPROVALS_ROLES_MODULES = Object.freeze({
  ROLE_1 :['PROFILE','DOCUMENTS','EXPENSES','CASH_ADVANCES','BENEFICIARIES'],
  ROLE_2 :['PROFILE'],
  ROLE_3 :['DOCUMENTS'],
  ROLE_4 :['BENEFICIARIES'],
});

export const CURRENCY_LIST = Object.freeze([
  { id: 'USD', value: 'USD', name: 'USD', symbol: '$' },
  { id: 'PHP', value: 'PHP', name: 'PHP', symbol: '₱' }
]);


export const USER_BENEFICIARY = Object.freeze({
  FILIPINO_POOL_CODE:[13009],
  FILIPINO_ALLOTMENT_LIMIT:3,
  OTHER_ALLOTMENT_LIMIT:2
});
 
export const STOLT_CONST = Object.freeze({
  SUCCESS_STATUS: 'success',

});

export const NOTIFICATION_MESSAGE = Object.freeze({
  FILE_SIZE_EXCEEDS: 'The file size exceeds the maximum allowed limit of 5MB. Please upload a smaller file.',
  FILE_SUPPORT_FORMET: 'Supported file format only JPEG and PNG.',
  DOCUMENT_LIMIT_EXCEED: 'Only 3 files are allowed.',
  ALLOTMENT_LIMIT_EXCEED: 'Only 3 beneficiaries are allowed.',
  BENEFICIARY_SAVED_SUCCESS:'Your beneficiary was saved successfully.',
  BENEFICIARY_SUBMITTED_SUCCESS:"Your beneficiaries were submitted for approval.",
  BENEFICIARY_ONE_SUBMITTED_SUCCESS:"Your beneficiary was submitted for approval."
});


export const USER_STATUS = Object.freeze({
  APPROVED: 'Approved',
  PENDING_APPROVAL: 'Pending approval',
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  REQUESTE_FOR_DELETION:'Submitted for deletion',
  MARKED_FOR_DELETION:'Marked for deletion'
})

export const DEFAULT_ACCCOUNT = Object.freeze({
   BANK: ['MARTRUST CORPORATION LIMITED','MARITIME PAYMENT SOLUTIONS LLC'],
   ACCOUNT:'123454321'
});

export const SUCCESS_MESSAGE = Object.freeze({
  PROFILE:"profile changes has been approved successfully.",
  FAMILY:"family changes has been approved successfully.",
  PROFILEFAMILY:"profile & family changes has been approved successfully.",
  DOCUMENT:"document changes has been approved successfully.",
  BENEFICIARY:"beneficiary has been approved successfully.",
});

export const REJECTED_MESSAGE = Object.freeze({
  PROFILE:"profile has been rejected successfully.",
  FAMILY:"family has been rejected successfully.",
  PROFILEFAMILY:"profile & family has been rejected successfully.",
  DOCUMENT:"document has been rejected successfully.",
  BENEFICIARY:"beneficiary has been rejected successfully.",
});

export const BTN_TEXT = Object.freeze({
  SAVE_AS_DRAFT: 'Save as draft',
  CANCEL_DRAFT: 'Cancel draft',
  UPDATE_DRAFT: 'Update draft',
  SAVE_AND_SUBMIT: 'Save as Submit',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
});

export const USER_STATUS_LABEL = Object.freeze([
  { status: 'Approved', label: 'Approved on', filed: 'APPROVED_ON', edit: true},
  { status: 'Draft', label: '',edit: false ,filed: 'REQUESTED_ON' },
  { status: 'Pending approval', label: 'Submitted on',edit: false , filed: 'REQUESTED_ON'},
  { status: 'Rejected', label: 'Rejected on',edit: true, filed: 'REQUESTED_ON' },
  { status: 'Submitted for deletion', label: 'Submitted on',edit: false, filed: 'REQUESTED_ON' },
  { status: 'Marked for deletion', label: 'Marked on',edit: true, filed: 'REQUESTED_ON' }
]);


export const INPUT_ERROR_MESSAGE = Object.freeze({
  CHARACTOR_ONLY: 'Special characters and numbers are not allowed.',
  NUMBER_ONLY: 'Only numbers are allowed.',
  SPECIAL: 'Special characters are not allowed.',
  MAXLENGTH:'You have reached the maximum characters limit for this field.',
  MAX:'You have reached the maximum limit for this field.',
  PERCENTAGE_DATA_FORMET:'Only 2 decimals are allowed.',
  PERCENTAGE_MAX:'Please enter a value between 0% and 100%.'
})


export const DOCUMENT_CATEGORY_TYPE = {
  TRAVEL: "TRAVEL",
  MEDICAL: "MEDICAL",
  LICENSES: "LICENSES/CONTRACTS",
  TRAINING: "TRAINING",
};

export const DOCUMENT_CATEGORY_TYPE_DATA = [
  {
    id: "TRAVEL",
    name: "TRAVEL"
  },
  {
    id: "MEDICAL",
    name: "MEDICAL"
  },
  {
    id: "LICENSES",
    name: "LICENSES/CONTRACTS"
  },
  {
    id: "TRAINING",
    name: "TRAINING"
  }
];


export const MEDICAL_FIT_UNFIT_STATUS = {
  "FIT WITHOUT RESTRICTIONS": "FIT WITHOUT RESTRICTIONS",
  "UNFIT": "UNFIT",
  "FIT WITH RESTRICTIONS": "FIT WITH RESTRICTIONS",
  "NOT APPLICABLE": "NOT APPLICABLE"
};

export const MEDICAL_FIT_UNFIT_STATUS_DATA = [
  {
    id: "FIT WITHOUT RESTRICTIONS",
    name: "FIT WITHOUT RESTRICTIONS"
  },
  {
    id: "UNFIT",
    name: "UNFIT"
  },
  {
    id: "FIT WITH RESTRICTIONS",
    name: "FIT WITH RESTRICTIONS"
  },
  {
    id: "NOT APPLICABLE",
    name: "NOT APPLICABLE"
  }
];


export const MEDICAL_NEG_POS_STATUS = {
  NEGATIVE: "NEGATIVE",
  POSITIVE: "POSITIVE",
};

export const MEDICAL_NEG_POS_STATUS_DATA = [
  {
    id: "NEGATIVE",
    name: "NEGATIVE"
  },
  {
    id: "POSITIVE",
    name: "POSITIVE"
  }
];

export const TRAVEL_DOC_TYPE = {
  PASSPORT: "PASSPORT",
  VISA: "VISA",
  VACCINATION: "VACC",
  SEAMANBOOK: "SBOOK"
}

export const TRAINING_INT_EXT_STATUS = {
  N: "INTERNAL",
  Y: "EXTERNAL"
};

export const TRAINING_INT_EXT_STATUS_DATA = [
  {
    id: "N",
    name: "INTERNAL"
  },
  {
    id: "Y",
    name: "EXTERNAL"
  }
];

export const VISA_ENTRY_TYPE = {
  "1 Entry": "1 Entry",
  "2 Entries": "2 Entries",
  "Multiple Entries": "Multiple Entries",
}

export const VISA_ENTRY_TYPE_DATA = [
  {
    id: "1 Entry",
    name: "1 Entry"
  },
  {
    id: "2 Entries",
    name: "2 Entries"
  },
  {
    id: "Multiple Entries",
    name: "Multiple Entries"
  }
];

export const VISA_DURATION_TYPE = {
  "60 Days": "60 Days",
  "90 Days": "90 Days",
  "120 Days": "120 Days",
  "180 Days": "180 Days"
}

export const VISA_DURATION_TYPE_DATA = [
  {
    id: "60 Days",
    name: "60 Days"
  },
  {
    id: "90 Days",
    name: "90 Days"
  },
  {
    id: "120 Days",
    name: "120 Days"
  },
  {
    id: "180 Days",
    name: "180 Days"
  }
];
export const VISA_DURATION_MCV_TYPE = {
  "3 years": "3 years"
 }


 export const VISA_DURATION_MCV_TYPE_DATA = [
  {
    id: "3 years",
    name: "3 years"
  }
];


export const DOCUMENT_API_CONSTANT = {
  MEDICAL: "medical-type",
  TRAINING: "training-type",
  TRAVEL: "travel-list",
  LICENSES: "license-type",
  FALGSTATE: ""
}
interface DocumentCategoryDetails {
  key_code: string;
  key_desc: string;
}

export const DOCUMENT_CATEGORY_KEY_MAP: {
  [key: string]: DocumentCategoryDetails;
} = {
  MEDICAL: {
    key_code: 'MEDICALTYPE_CODE',
    key_desc: 'DESCRIPTION'
  },
  TRAINING: {
    key_code: 'TRAINING_CODE',
    key_desc: 'TRAINING_DESCRIPTION'
  },
  TRAVEL: {
    key_code: 'DOCCATEGORY_CODE',
    key_desc: 'DESCRIPTION'
  },
  LICENSES: {
    key_code: 'LICENSE_CODE',
    key_desc: 'LICENSE_DESCRIPTION'
  }
} as const;

// Define an interface for Document Fields
interface DocumentField {
  label: string;
  key: string;
  type: string;
  split: boolean;
  postion?: number; // Optional, since not all fields have this property
}

// Define an interface for Categories
type DocumentCategory = 'TRAVEL' | 'MEDICAL' | 'LICENSES' | 'TRAINING';


type DocumentFieldType = {
  label: string;
  key: string;
  type: string;
  options?: any;
  split: boolean;
  formControlName?: string,
  position?: number;
  condition_on?: string;
  optionKey?: string;
  exclude?: string[];
  excludeKey?: string;
  required?: boolean// Add optional properties if they exist
};

type DocumentFieldsMap = {
  [key in DocumentCategory]: {
    [key: string]: DocumentFieldType[];
  };
};

// Define the DOCUMENT_FIELDS constant with the appropriate type

export const ADD_DOCUMENT_FIELDS: any = {
  TRAVEL: {
    PASS: [
      { label: 'Passport number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true, key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SBOOK: [
      { label: "Seaman's document type", required: true, key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Document number', required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued country', required: true,key: 'COUNTRY_CODE', type: "select-child", split: false },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
     
    ],
    VISA: [
      { label: 'Visa type', key: 'SUB_TYPE_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Visa entry', key: 'VISA_ENTRY', type: "select",options:VISA_ENTRY_TYPE_DATA, split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER','VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa duration', key: 'VISA_DURATION', options:[], type: "select-child", split: true, exclude: ['VISA-C1/D', 'VISA-B1/B2', 'OTHER', 'VISA'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Visa number', key: 'DOC_NO',required: true, type: "string", split: false },
      { label: 'Visa country', key: 'COUNTRY_CODE',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued country', key: 'VISADOC_COUNTRY',required: true, type: "select-child", options: [], split: false },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
    ],
    VACC: [
      { label: "Vaccination type", required: true,key: 'SUB_TYPE_CODE', type: "select-child", options: [], split: false },
      { label: 'Comments', key: 'COMMENTS',required: false, type: "string", split: true, exclude: ['VACCYF'], excludeKey: 'SUB_TYPE_CODE' },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE', required: true,type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently', key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  MEDICAL: {
    PREEMPL: [
      { label: 'Fit/Unfit',required: true, key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS_DATA, split: true },
      { label: 'Issued date', key: 'ISSUE_DATE',required: true, type: "date", split: false },
      { label: 'Expiry date', key: 'EXPIRE_DATE',required: true, type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }
    ],
    DRUGALCO: [
      { label: 'Positve Negative',required: true, key: 'COMMENTS', type: "select", options: MEDICAL_NEG_POS_STATUS_DATA, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false }
    ],
    SUPPMED: [
      { label: 'Fit/Unfit', required: true,key: 'FITUNIT', type: "select", options: MEDICAL_FIT_UNFIT_STATUS_DATA, split: true },
      { label: 'Issued date', required: true,key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false },
      { label: 'Comments (optional)', key: 'COMMENTS', type: "textarea", split: false }

    ]
  },
  LICENSES: {
    FORM: [
      { label: 'Number', required: true,key: 'DOC_NO', type: "string", split: false },
      { label: 'Issued at',required: true, key: 'ISSUE_AT', type: "select-child", options: [], split: false },
      { label: 'Issued by (optional)', key: 'ISSUE_BY', type: "string", split: false },
      { label: 'Issued date',required: true, key: 'ISSUE_DATE', type: "date", split: false },
      { label: 'Expiry date', required: true,key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
    ]
  },
  TRAINING: {
    FORM: [
      { label: 'Number',required: true, key: 'DOC_NO', type: "string", split: false },
      { label: 'Start date',required: true, key: 'START_DATE', type: "date", split: false },
      { label: 'End date',required: true, key: 'END_DATE', type: "date", split: false },
      { label: 'Expiry date',required: true, key: 'EXPIRE_DATE', type: "date", split: false, condition_on: 'NO_EXPIRY' },
      { label: 'Issued permanently',required: false, key: 'NO_EXPIRY', type: "checkbox", split: false },
      { label: 'Internal or external',required: true, key: 'INTERNAL', type: "select", options: TRAINING_INT_EXT_STATUS_DATA, split: false },
      { label: 'Conducted at (optional)', key: 'CONDUCT_AT', type: "select-child", options: [], split: false },
    ]
  }

};

export const DOCUMENT_FIELDS = Object.freeze({
  TRAVEL: {
     PASS:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Passport number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
      ],
      SBOOK:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Seaman's document type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Document number',key:'DOC_NO',type:"string",split:false},
        {label:'Issued country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
      VISA:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:'Visa type',key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Visa entry',key:'COMMENTS',type:"string",split:true,postion:0,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa duration',key:'COMMENTS',type:"string",split:true,postion:1,exclude:['VISA-C1/D','VISA-B1/B2','OTHER']},
        {label:'Visa number',key:'DOC_NO',type:"string",split:false},
        {label:'Visa country',key:'COUNTRY_NAME',type:"string",split:false},
        {label:'Issued country',key:'VISADOC_COUNTRY_NAME',type:"string",split:false},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
      ],
      VACC:[
        {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
        {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
        {label:"Vaccination type",key:'SUB_TYPE_NAME',type:"string",split:false},
        {label:'Comment',key:'COMMENTS',type:"string",split:false,exclude:['VACCYF']},
        {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
        {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']}
      ],
  },
  MEDICAL:{
    PREEMPL:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1},
    ],
    DRUGALCO:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Positve Negative',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false}
    ],
    SUPPMED:[
      {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
      {label:'Type',key:'DOCTYPE_NAME',type:"string",split:false},
      {label:'Fit/Unfit',key:'COMMENTS',type:"string",split:true,postion:0},
      {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
      {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false},
      {label:'Comment',key:'COMMENTS',type:"string",split:true,postion:1}
    ]
  },
  LICENSES: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Issued at',key:'ISSUE_AT_NAME',type:"string",split:false},
    {label:'Issued by',key:'ISSUE_BY',type:"string",split:false},
    {label:'Issued date',key:'ISSUE_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
  ],
  TRAINING: [
    {label:'Category',key:'CATEGORY_NAME',type:"string",split:false},
    {label:'Name/Code',key:'DOCTYPE_NAME',type:"string",split:false},
    {label:'Number',key:'DOC_NO',type:"string",split:false},
    {label:'Start date',key:'START_DATE',type:"date",split:false},
    {label:'End date',key:'END_DATE',type:"date",split:false},
    {label:'Expiry date',key:'EXPIRE_DATE',type:"date",split:false,exclude:['NO_EXPIRY']},
    {label:'Internal or external',key:'INTERNAL_NAME',type:"string",split:false},
    {label:'Conducted at',key:'CONDUCT_NAME',type:"string",split:false},
  ]
})

export const ISSUE_LIST = Object.freeze([
  { id: 'poor_quality_scan', value: 'Poor quality of the scan', name: 'Poor quality of the scan' },
  { id: 'does_not_match_document_type', value: 'The scan does not match the document type', name: 'The scan does not match the document type' },
  { id: 'others_contact_office', value: 'Others - please contact the office', name: 'Others - please contact the office' }
]);

export const BENEFICIARIES_ISSUE_LIST = Object.freeze([
  { id: 'input_vs_attachment_mismatch', value: 'Input vs attachment mismatch', name: 'Input vs attachment mismatch' },
  { id: 'incorrect_account_number', value: 'Card number was provided instead of account number', name: 'Card number was provided instead of account number' },
  { id: 'not_readable_attachment', value: 'Not readable/ visible handwriting/ attachment', name: 'Not readable/ visible handwriting/ attachment' },
  { id: 'usd_bank_account', value: 'USD bank account', name: 'USD bank account' },
  { id: 'incorrect_account_numbers', value: 'Incorrect account numbers', name: 'Incorrect account numbers' },
  { id: 'incorrect_incomplete_attachment_provided', value: 'Incorrect / incomplete attachment provided', name: 'Incorrect / incomplete attachment provided' },
  { id: 'duplicate_request', value: 'Duplicate request', name: 'Duplicate request' },
  { id: 'others_contact_office', value: 'Others', name: 'Others' },
]);


