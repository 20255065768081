export const environment = {
  production: false,
  navType:'dev',
  BACKEND_API_BASE_URL: 'https://api.stoltdev.com/css-manager-apis/v1',
  
  AAD_CLIENT_ID: "36e3b292-4f37-4284-828c-1343a47a4b97",
  AAD_AUTHORITY: "https://login.microsoftonline.com/74437d1e-19e1-4555-bfcb-c6d8bace6725",
  AAD_REDIRECT_URI: "https://cssoffice.stoltdev.com",
  AAD_GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  AAD_USER_API_SCOPE: 'api://1ff82eac-6bc0-4acc-a30f-ee3c9909bde6/.default'
};