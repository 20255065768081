import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApprovalFiltersService {
  public getFilteredData(dataList: any[], filters: any, nameField: string, idField: string = nameField, customOrderFunc: Function | null = null) {
    let data = dataList.filter((item: any) => {
      const categories: boolean = this.categoriesFilters(filters, item);
      const ranks: boolean = this.ranksFilters(filters, item);
      const status: boolean = this.statusFilters(filters, item);
      const search: boolean = this.searchFilters(filters, item);
      const expiry: boolean = this.expiryFilters(filters, item);
      const poolcode: boolean = this.poolcodeFilters(filters, item);
      return categories && ranks && status && search && expiry && poolcode;
    });
  
    let result: any[] = [];
    data.forEach((ele: any) => {
      let dataNameExist: any = result.find((x: any) => x.name === ele[nameField]);
      if (ele[nameField] && !dataNameExist) {
        let order = customOrderFunc ? customOrderFunc().find((x: any) => x.name === ele[nameField])?.order || 1 : 1;
        result.push({ id: ele[idField], name: ele[nameField], order });
      }
    });
  
    // Sort the result based on 'order' or 'name' if no customOrderFunc is provided
    return result.sort((a: any, b: any) => b.order < a.order ? 1 : -1);
  }

  categegoryOrder(){
    return [
      { id : 1, name : 'Travel', order: 1 },
      { id : 2, name : 'Medical', order: 2 },
      { id : 3, name : 'Licenses/Contracts', order: 3 },
      { id : 4, name : 'Training', order: 4 },
    ];
  }
  public filterDocuments(addDataList: any[], filters: any): any[] {
    return addDataList.filter((item: any) => {
      const ranks: boolean = this.ranksFilters(filters, item);
      const categories: boolean = this.categoriesFilters(filters, item);
      let types: boolean = false; // Declare types as a boolean variable
      if (item?.TYPE) {
        types = this.beneficiariesTypesFilters(filters, item);
      } else {
        types = this.typesFilters(filters, item);
      }
  
      const status: boolean = this.statusFilters(filters, item);
      const search: boolean = this.searchFilters(filters, item);
      const expiry: boolean = this.expiryFilters(filters, item);
      const poolcode: boolean = this.poolcodeFilters(filters, item);
  
      // Return true if all conditions are satisfied
      return ranks && categories && types && status && search && expiry && poolcode;
    });
  }
  public ranksFilters(filters: any, item: any){
    return this.filterByProperty(filters.ranks, item, 'RANK_CODE');
  }

  public categoriesFilters(filters: any, item: any){
    return this.filterByProperty(filters.categories, item, 'CATEGORY_CODE');
  }

  public typesFilters(filters: any, item: any){
    return this.filterByProperty(filters.types, item, 'DOC_TYPE_NAME');
  }

  public beneficiariesTypesFilters(filters: any, item: any){
    return this.filterByProperty(filters.types, item, 'TYPE');
  }

  public statusFilters(filters: any, item: any){
    return this.filterByProperty(filters.status, item, 'STATUS');
  }
  public poolcodeFilters(filters: any, item: any){
    return this.filterByProperty(filters.poolcode, item, 'POOL_CODE');
  }

  public expiryFilters(filters: any, item: any){
    return filters.expiry.reduce((prev:any, curr:any, currentIndex:number) => { 
      if (this.monthDiff(item?.EXPIRE_DATE) && (this.monthDiff(item?.EXPIRE_DATE) <= curr.id)) {
        return true;
      }
      else if(currentIndex == 0) {
        return false;
      }
      else {
        return prev;
      }
      }, true);
  }

  public searchFilters(filters: any, item: any){
    return filters.search.reduce((prev:any, curr:any, currentIndex:number) => { 
       if (item?.CREW_NAME && item?.CREW_NAME?.toLowerCase().includes(curr?.toLowerCase())||item?.CREW_IPN && item?.CREW_IPN?.toLowerCase().includes(curr?.toLowerCase())) {
         return true;
       }
       else if(currentIndex == 0) {
         return false;
       }
       else {
         return prev;
       }
       }, true);
   }

  private filterByProperty(filterArray: any[], item: any, property: string) {
    return filterArray.reduce((prev: any, curr: any, currentIndex: number) => {
      if (item?.[property] && item?.[property].includes(curr.id)) {
        return true;
      } else if (currentIndex == 0) {
        return false;
      } else {
        return prev;
      }
    }, true);
  }

  public monthDiff(date:any) {
    let d1 = new Date();
    let d2 = new Date(date);
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  /*********************************************** */

  public filterByPropertyLatest(filterArray: any[], item: any, key:any,search:string = '') {
    return filterArray.reduce((prev: any, curr: any, currentIndex: number) => {
      if ((key && key.length > 0) ? item[key[0]]?.toLowerCase().includes((!search) ? curr.id.toLowerCase() : curr.toLowerCase()) || item[key[1]]?.toLowerCase().includes((!search) ? curr.id.toLowerCase() : curr.toLowerCase()) :
        item?.toLowerCase().includes((!search) ? curr.id.toLowerCase() : curr.toLowerCase())) {
        return true;
      } else if (currentIndex == 0) {
        return false;
      } else {
        return prev;
      }
    }, true);
  }

  public filters(dataList:any, filters:any, filed:string = '', filtersData:any = [],allData:boolean = false) {
    let data = dataList.filter((item:any) => {
      const filterResults = filtersData.reduce((acc: {[key: string]: boolean}, it: {key: string, dataKey: string}) => ({
        ...acc,
        [it.key]: this.filterByPropertyLatest(filters[it.key], (typeof item[it.dataKey] !== 'string') ? item : item[it.dataKey] ,(typeof item[it.dataKey] !== 'string') ? it.dataKey : '',it.key == 'search' ? 'search' : '') 
      }), {categories: true});

      return filtersData
        .filter((it: {key: string, dataKey: string}) => filed != it.dataKey)
        .every((it: {key: string}) => filterResults[it.key]);
    });

    let dataObject = data
      .filter((ele: {[key: string]: any}) => ele?.[filed])
      .reduce((acc: any[], ele: {[key: string]: any}) => {
        if (!acc.find(x => x.name === ele[filed])) {
          acc.push({ id: ele[filed], name: ele[filed] });
        }
        return acc;
      }, []);

    const filterConfig = filtersData.find((it: any) => it.dataKey === filed);
    let sortData = filterConfig?.order?.length > 0 ? 
      this.sortDropdownList(dataObject, filterConfig?.order) : 
      dataObject.sort((a:any, b:any) => b.name < a.name ? 1 : -1);
      return (allData) ? data : sortData;
  }

  public sortDropdownList(array: { id: string; name: string }[],order: string[])
  {
    // Create a map for ordering priority
    const orderIndex = new Map(order.map((id, index) => [id, index]));
  
    // Sort the categories
    return array.sort((a, b) => {
      const indexA = orderIndex.has(a.id) ? orderIndex.get(a.id)! : Infinity;
      const indexB = orderIndex.has(b.id) ? orderIndex.get(b.id)! : Infinity;
  
      if (indexA === indexB) {
        return a.id.localeCompare(b.id); // Alphabetical order for items not in `order`
      }
      return indexA - indexB;
    });
  }

}